const formValidation = {
    // 正整数
    validateNumber(rule, value, callback) {
        if (value !== '') {
            // 非零的正整数
            let numberReg = /^\+?[1-9][0-9]*$/;
            if (!numberReg.test(value)) {
                callback(new Error('请输入大于0的整数'))
            } else {
                callback()
            }
        } else {
            callback()
        }
    },
    // 库存校验
    validateStockNumber(rule, value, callback) {
        if (value !== '') {
            // 非零的正整数
            let numberReg = /^([1-9]\d{0,2}|0)$/;
            if (!numberReg.test(value)) {
                callback(new Error('请输入0-999的整数'))
            } else {
                callback()
            }
        } else {
            callback()
        }
    },
    // 金额校验
    validateNumberComdify(rule, value, callback) {
        if (value !== '') {
            if (!value) return value;
            let numberReg = /^(([1-9]\d{0,2}|0)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/
            if (!numberReg.test(value)) {
                callback(new Error('整数3位,小数2位'))
            } else {
                callback()
            }
        } else {
            callback()
        }
    },
    // 全角(中文)
    validateChinese(rule, value, callback) {
        // a.全角空格为12288，半角空格为32
        // b.其他字符半角(33-126)与全角(65281-65374)的对应关系是：均相差65248
        if (value !== '') {
            let reg = /^[\u4e00-\u9fa5]+$/;
            if (!reg.test(value)) {
                callback(new Error('请输入汉字'))
            } else {
                callback()
            }
        } else {
            callback()
        }
    },
    //验证手机号码的规则
    validateMobile(rule,value,callback){
        if (value !== '') {
            let reg = /^((1[3,5,8,7,9][0-9])|(14[5,7])|(17[0,6,7,8])|(19[7]))\d{8}$/;
            if (!reg.test(value)) {
                callback(new Error('手机号码格式不正确'))
            } else {
                callback()
            }
        } else {
            callback()
        }
    },
    //邮箱校验
    validateEmail(rule,value,callback){
        if (value !== '') {
            let reg = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
            if (!reg.test(value)) {
                callback(new Error('邮箱格式不正确'))
            } else {
                callback()
            }
        } else {
            callback()
        }
    },
}
export default formValidation
