<template>    
   <div class="fill_wrap">
        <div class="fill_cont w_max_min">
            <div class="top_logo"><router-link to="/"><img src="@/assets/img/logo.png" class="top_logoImg" title="商易融"/></router-link></div>
            <div class="fill_box inner_common">
                <h2 class="flex_center fill_title">信息填写</h2>
                <el-form :model="fillForm" :rules="fillRules" ref="fillForm" class="fill_form">
                    <div class="err_bg" v-show="errFlag"><i class="iconfont icon-jinggao"/> &nbsp;<span>{{errMsg}}!</span></div>
                    <el-form-item label="公司名称:" prop="cnCompany">
                        <el-input v-model="fillForm.cnCompany" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="统一社会信息代码:" prop="creditCore">
                        <el-input v-model="fillForm.creditCore" maxlength="18" @focus='errFlag=false'></el-input>
                    </el-form-item>
                    <el-form-item label="联系人姓名:" prop="cnName">
                        <el-input v-model="fillForm.cnName" maxlength="20" @focus='errFlag=false'></el-input>
                    </el-form-item>
                    <el-form-item label="所属部门:" prop="dept">
                        <el-input v-model="fillForm.dept" maxlength="20" @focus='errFlag=false'></el-input>
                    </el-form-item>
                    <el-form-item label="职位名称:" prop="position">
                        <el-input v-model="fillForm.position"  maxlength="20" @focus='errFlag=false'></el-input>
                    </el-form-item>
                    <el-form-item label="手机号码:" prop="phone">
                        <el-input v-model="fillForm.phone"  maxlength="11" @focus='errFlag=false'></el-input>
                    </el-form-item>
                    <el-form-item label="座机号码:" prop="tel">
                        <el-input v-model="fillForm.tel"  maxlength="20" @focus='errFlag=false'></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱账号:" prop="email">
                        <el-input v-model="fillForm.email"  maxlength="50" @focus='errFlag=false'></el-input>
                    </el-form-item>
                    <el-form-item label="银行账号:" prop="bankAccount">
                        <el-input v-model="fillForm.bankAccount" maxlength="17" @focus='errFlag=false'></el-input>
                    </el-form-item>
                    <el-form-item label="收款银行:" prop="bankReceiving">
                        <el-input v-model="fillForm.bankReceiving" maxlength="17" @focus='errFlag=false'></el-input>
                    </el-form-item>
                    <el-form-item label="填写公司简介:" prop="companyProfile">
                        <el-input type="textarea" v-model="fillForm.companyProfile" :autosize="{ minRows: 3, maxRows: 3 }"  maxlength="200" show-word-limit></el-input>
                    </el-form-item>
                    <div class="flex_box">
                        <router-link class="btn_link footer_button1 m_top btn_shadow" :to="{ name: 'Dashboard', params: { choseRole: true }}">立即登录</router-link>
                        <el-button class="footer_button1 m_top btn_shadow" type="primary" @click="submitForm('fillForm')">提交信息</el-button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import {getUserInfo,editUser} from "@/api/api.js";
import formValidation from "@/utils/rule.js";
export default {
    data(){
      const valBankReceiving = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入收款银行'))
      } else if (/^[\u4e00-\u9fa5a-zA-Z0-9]+$/.test(val) == false) {
        return cb(new Error("请输入20位中英文或数字"));
      }
      return cb()
    }
        return{
            userId:this.$route.params.userId,
            fillForm:{
               cnCompany:'' ,
               creditCore:'',
               cnName:'',
               dept:'',
               position:'',
               phone:'',
               tel:'',
               email:'',
               bankAccount:'',
               bankReceiving:'',
               companyProfile:'',
               roleId:'',
               isValid: '-1'
            },
            fillRules:{ 
            creditCore:[{ required: true, message: '请输入统一社会信息代码', trigger: 'blur' }],
            cnName:[{ required: true, message: '请输入联系人姓名', trigger: 'blur' }],
            phone:[{ required: true, message: '请输入手机号码', trigger: 'blur' },{validator:formValidation.validateMobile, trigger: "blur"}],
            email:[{ required: true, message: '请输入邮箱账号', trigger: 'blur' },{validator:formValidation.validateEmail, trigger: "blur"}],
            bankAccount:[{ required: true, message: '请输入银行账号', trigger: 'blur' }],
            bankReceiving:[{ required: true, validator: valBankReceiving, trigger: 'blur' }],
          },
          errMsg:'邮箱或手机号码已注册',
          errFlag:false,//是否显示邮箱或手机号码
        }
    },
    created(){
        this.getUserInfo()
    },
    methods:{
        //提交注册表单
        submitForm(fillForm) {
        console.log(fillForm)
          this.$refs[fillForm].validate((valid) => {
          if (valid) {
            this.editInfo()
          } else {
            return false;
          }
        });
        },

        //完善信息
        async editInfo(){
            this.fillForm.isValid = '-1'
            let res=await editUser(this.fillForm)
            if(res && res.code==200){
                 this.$router.push({ name: 'Dashboard', params: { popLoginForm: true } })
            }
            else{
                this.errFlag=true
                this.errMsg=res.msg
            }
        },

       //userId查询获取基础信息
       async getUserInfo(){
            let res=await getUserInfo('userId=' + this.userId)
            if(res&&res.code==200){
                this.fillForm=res.data
            }
       },
      
    }
}
</script>
<style scoped>
.fill_wrap{
    top: 0;
    left: 0;
    width:100%;
    min-height:100%;
    min-width: 1280px;
    z-index:0;
    zoom: 1;
    background: url('../../assets/img/reg_bg.png') no-repeat;
    background-color: #F7FAFC;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
}
.fill_cont{
    width: 100%;
    padding:70px 0 120px;
}
.w_max_min{
    max-width: 1600px;
    min-width: 1280px;
    margin: auto;
}
.top_logo{
    position: absolute;
    top:20px;
}
.top_logoImg{
    height: 20px;
}
.fill_box{
    width: 550px;
    background: #FFFFFF;
    border-radius: 10px;
    margin: auto;
    padding:30px 30px 40px;
}
.fill_title{
    font-size: 20px;
    color: #162747;
    line-height: 26px;
    margin-bottom: 22px;
}
.el-form{
    position: relative;
}
.fill_form /deep/ .el-textarea__inner,
.fill_form /deep/ .el-input__inner{
    background: #F7FAFC;
    border: 1px solid #E1E7EA;
    color: #162747;
}
.fill_form /deep/ .el-textarea__inner{
    height: 80px !important;
}
.fill_form /deep/ .el-input.is-disabled .el-input__inner{
    background: #E1E7EA;
    color: #7C969B;
}
.fill_form /deep/ .el-form-item__label{
    width: 144px;
}
.fill_form /deep/ .el-form-item__content{
    margin-left: 144px;
}
.btn_link{
    padding: 0;
    text-align: center;
    line-height: 40px;
    margin-left: 44px;
    margin-right: 50px;
}
.m_top{
     margin-top: 10px;
}
.err_bg{
    position: absolute;
    width: 280px;
    height: 68px;
    line-height: 58px;
    font-size: 14px;
    color: #FB6476;
    text-align: center;
    background-image: url(../../assets/img/log_err_bg.png);
    background-size: 280px 66px;
    background-repeat: no-repeat;
    top: -58px;
    left: 21.5%;
    z-index: 88;
}

.err_bg .icon-jinggao{
    font-size: 16px;
    color: #FB6476;
}
@media screen and (max-width: 1664px) {
.fill_title {
    font-size: 13px;
    line-height: 17px;
    margin-bottom: 15px;
}
.fill_cont {
    padding: 46px 79px;
}
.top_logoImg {
    height: 13px;
}
.fill_form >>> .el-form-item__label{
    width: 109px;
    padding-right: 8px;
}
.fill_form >>> .el-form-item__content{
    margin-left: 109px;
}
.fill_box {
    width: 363px;
    border-radius: 7px;
    padding: 20px 20px 26px;
}
.m_top {
    margin-top: 7px;
}
.err_bg{
    width: 185px;
    height: 45px;
    line-height: 38px;
    font-size: 12px;
    background-size: 185px 44px;
    top: 12px;
    left: 20%;
}
.err_bg .icon-jinggao{
    font-size: 12px;
}
.btn_link {
    line-height: 29px;
    margin-left: 29px;
    margin-right: 33px;
}

}
</style>