// 引入封装好的axios
import axios,{ apiUrl } from "./request";
//定义跨域代理路由

// const api = process.env.NODE_ENV == "development" ? '/devapi' : '/api'
// const api = process.env.NODE_ENV == "development" ? '/devapi' : 'https://www.o2c-scf.com/api' 
const api = process.env.NODE_ENV == "development" ? '/devapi' : apiUrl()
// const loginapi = process.env.NODE_ENV == "development" ? '/devapi' + '/service-organiza' : '/api'
//  /api 为配置跨域的路径变量
// 登出
// export const logout = (params) => {
//     return axios.get(loginapi + '/ums/login/logout')
// }
//查看报告
export const downloadExport = (params) => {
  return axios.post(api + '/service/getReport?' + params, { type: "download" })
}
//上传图片
export const uploadImg = (params) => {
  return axios.post(api + '/pdt/product/uploadPictureFile', params, { type: "upload" })
}
//导出excel
export const ExportExcel = (params) => {
  return axios.post(api + '/pdt/product/downloadProductExcel', params, { type: "download" })
}

//用户登陆
export const doLogin = (params) => {
  return axios.get(api + '/sys/login?' + params)
}

//注销登陆
export const loginOut = (params) => {
  return axios.post(api + '/sys/loginout')
}

//用户注册
export const editUser = (params) => {
  return axios.post(api + '/sys/editUser', params)
}

//通过userId获取用户
export const getUserInfo = (params) => {
  return axios.post(api + '/sys/getUserInfo?' + params)
}

//发票上传
export const uploads = (params) => {
  return axios.post(api + '/upload/uploads', params, { type: "upload" })
}
//数据字典
export const findCodeInfoByKey = (params) => {
  return axios.post(api + '/codeInfo/findCodeInfoByKey?' + params)
}

//国家省市接口
export const getAllCity = (params) => {
  return axios.post(api + '/personal/getCPCity?' + params)
}

//查询供应商+客户名称
export const getCompanyNameList = (params) => {
  return axios.post(api + '/sys/CompanyNameList?' + params)
}

//服务商账号注册
export const serviceRegister = (params) => {
  return axios.post(api + '/service/serviceRegister', params)
}

// // 注册成功后跳转
// export const getH5UrlGo = (params, headers) => {
//   return axios.post(api2 + '/credit-api/report/wfqClient/getH5Url', params, { rzheader: headers })
// }

// 请求头信息
export const getCreateSignature = (params) => {
  return axios.post(api + '/supplierCredit/createSignature', params)
}

